import { default as _91type_93_45dashboardz6eOdc30PrMeta } from "/vercel/path0/pages/[type]-dashboard.vue?macro=true";
import { default as _91uniqueName_93gsyTJjOvPYMeta } from "/vercel/path0/pages/[uniqueName].vue?macro=true";
import { default as indexQZtxM4pZNmMeta } from "/vercel/path0/pages/apply-as-industry-pro/index.vue?macro=true";
import { default as successL14v13XNqmMeta } from "/vercel/path0/pages/apply-as-industry-pro/success.vue?macro=true";
import { default as _91uniqueName_93vye2DmV2HRMeta } from "/vercel/path0/pages/artist/[uniqueName].vue?macro=true";
import { default as _91orderId_93ae9ROS6rOgMeta } from "/vercel/path0/pages/call/[orderId].vue?macro=true";
import { default as calls5zalHHdL4EMeta } from "/vercel/path0/pages/calls.vue?macro=true";
import { default as chat1HrNrTjf3wMeta } from "/vercel/path0/pages/chat.vue?macro=true";
import { default as dashboardHdtnClvoGAMeta } from "/vercel/path0/pages/dashboard.vue?macro=true";
import { default as demo_45submissions_45oldgo0xd4ZsYoMeta } from "/vercel/path0/pages/demo-submissions-old.vue?macro=true";
import { default as discoverDujjR35yg8Meta } from "/vercel/path0/pages/discover.vue?macro=true";
import { default as feedback1zgaUMjbuXMeta } from "/vercel/path0/pages/feedback.vue?macro=true";
import { default as impersonaterw9JypSkoGMeta } from "/vercel/path0/pages/impersonate.vue?macro=true";
import { default as index_45oldVNbYTfRXPlMeta } from "/vercel/path0/pages/index-old.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91_46_46_46slugs_93guCiENn06qMeta } from "/vercel/path0/pages/industry-pros/[...slugs].vue?macro=true";
import { default as indexh0nIylRQ4kMeta } from "/vercel/path0/pages/industry-pros/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as musicidQLHAkdMCMeta } from "/vercel/path0/pages/music.vue?macro=true";
import { default as my_45_91type_93_45profileyebcZFoPCMMeta } from "/vercel/path0/pages/my-[type]-profile.vue?macro=true";
import { default as _91opportunitySlug_93YSbCCvcanJMeta } from "/vercel/path0/pages/my-opportunities-old/[opportunitySlug].vue?macro=true";
import { default as _91opportunitySubmissionId_93OkeYZvvWX6Meta } from "/vercel/path0/pages/my-opportunities-old/[opportunitySlug]/[opportunitySubmissionId].vue?macro=true";
import { default as index30vJRR94BfMeta } from "/vercel/path0/pages/my-opportunities-old/index.vue?macro=true";
import { default as _91opportunitySlug_934GQ4u23KBhMeta } from "/vercel/path0/pages/my-opportunities/[opportunitySlug].vue?macro=true";
import { default as indexVTA3xhc60HMeta } from "/vercel/path0/pages/my-opportunities/index.vue?macro=true";
import { default as _91opportunityId_93QSGozP7tnNMeta } from "/vercel/path0/pages/opportunity/[opportunityId].vue?macro=true";
import { default as profiletSnesj0NHTMeta } from "/vercel/path0/pages/opportunity/[opportunityId]/profile.vue?macro=true";
import { default as questionDu3ra51uz0Meta } from "/vercel/path0/pages/opportunity/[opportunityId]/question.vue?macro=true";
import { default as select_45trackLMmVV6usUaMeta } from "/vercel/path0/pages/opportunity/[opportunityId]/select-track.vue?macro=true";
import { default as success42RBmg2XXuMeta } from "/vercel/path0/pages/opportunity/success.vue?macro=true";
import { default as post_45onboardingQm5R02hDeHMeta } from "/vercel/path0/pages/post-onboarding.vue?macro=true";
import { default as _91uniqueId_93zzqNQI3AS5Meta } from "/vercel/path0/pages/pro-onboarding/[uniqueId].vue?macro=true";
import { default as errorM8dYKdYKWpMeta } from "/vercel/path0/pages/pro-onboarding/error.vue?macro=true";
import { default as _91uniqueName_93uqzvQIfBmuMeta } from "/vercel/path0/pages/purchase/[uniqueName].vue?macro=true";
import { default as checkout8RRcsq18vEMeta } from "/vercel/path0/pages/purchase/checkout.vue?macro=true";
import { default as about_45yourselfGQCBPoR6kpMeta } from "/vercel/path0/pages/purchase/checkout/about-yourself.vue?macro=true";
import { default as extra_45contentUd5gibn0AMMeta } from "/vercel/path0/pages/purchase/checkout/extra-content.vue?macro=true";
import { default as feedback_45topicTx2HslfrpMMeta } from "/vercel/path0/pages/purchase/checkout/feedback-topic.vue?macro=true";
import { default as pay2GqWfjYG0sMeta } from "/vercel/path0/pages/purchase/checkout/pay.vue?macro=true";
import { default as phone_45numberPCVO3aRrKsMeta } from "/vercel/path0/pages/purchase/checkout/phone-number.vue?macro=true";
import { default as pick_45timeP0wTkVpbpuMeta } from "/vercel/path0/pages/purchase/checkout/pick-time.vue?macro=true";
import { default as reviewqcYDVqkXt1Meta } from "/vercel/path0/pages/purchase/checkout/review.vue?macro=true";
import { default as select_45stage4BMtNiXiNYMeta } from "/vercel/path0/pages/purchase/checkout/select-stage.vue?macro=true";
import { default as select_45trackNHMyWeWsY3Meta } from "/vercel/path0/pages/purchase/checkout/select-track.vue?macro=true";
import { default as success2jT8z7kuIeMeta } from "/vercel/path0/pages/purchase/success.vue?macro=true";
import { default as _91requestId_93svNXBI9S4OMeta } from "/vercel/path0/pages/rating/[userId]/[requestId].vue?macro=true";
import { default as signupY93ILdpGpbMeta } from "/vercel/path0/pages/signup.vue?macro=true";
import { default as indextteqWBX13wMeta } from "/vercel/path0/pages/submit/index.vue?macro=true";
import { default as profilekQuiABvXUVMeta } from "/vercel/path0/pages/submit/profile.vue?macro=true";
import { default as successLuGi3A7cWNMeta } from "/vercel/path0/pages/submit/success.vue?macro=true";
import { default as trackxkk8w3363SMeta } from "/vercel/path0/pages/submit/track.vue?macro=true";
import { default as wishlistakmJn3oDDeMeta } from "/vercel/path0/pages/wishlist.vue?macro=true";
import { default as xposure_45playlists5jDMQR6dDKMeta } from "/vercel/path0/pages/xposure-playlists.vue?macro=true";
export default [
  {
    name: _91type_93_45dashboardz6eOdc30PrMeta?.name ?? "type-dashboard",
    path: _91type_93_45dashboardz6eOdc30PrMeta?.path ?? "/:type-dashboard",
    children: [],
    meta: _91type_93_45dashboardz6eOdc30PrMeta || {},
    alias: _91type_93_45dashboardz6eOdc30PrMeta?.alias || [],
    redirect: _91type_93_45dashboardz6eOdc30PrMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[type]-dashboard.vue").then(m => m.default || m)
  },
  {
    name: _91uniqueName_93gsyTJjOvPYMeta?.name ?? "uniqueName",
    path: _91uniqueName_93gsyTJjOvPYMeta?.path ?? "/:uniqueName",
    children: [],
    meta: _91uniqueName_93gsyTJjOvPYMeta || {},
    alias: _91uniqueName_93gsyTJjOvPYMeta?.alias || [],
    redirect: _91uniqueName_93gsyTJjOvPYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[uniqueName].vue").then(m => m.default || m)
  },
  {
    name: indexQZtxM4pZNmMeta?.name ?? "apply-as-industry-pro",
    path: indexQZtxM4pZNmMeta?.path ?? "/apply-as-industry-pro",
    children: [],
    meta: indexQZtxM4pZNmMeta || {},
    alias: indexQZtxM4pZNmMeta?.alias || [],
    redirect: indexQZtxM4pZNmMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/apply-as-industry-pro/index.vue").then(m => m.default || m)
  },
  {
    name: successL14v13XNqmMeta?.name ?? "apply-as-industry-pro-success",
    path: successL14v13XNqmMeta?.path ?? "/apply-as-industry-pro/success",
    children: [],
    meta: successL14v13XNqmMeta || {},
    alias: successL14v13XNqmMeta?.alias || [],
    redirect: successL14v13XNqmMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/apply-as-industry-pro/success.vue").then(m => m.default || m)
  },
  {
    name: _91uniqueName_93vye2DmV2HRMeta?.name ?? "artist-uniqueName",
    path: _91uniqueName_93vye2DmV2HRMeta?.path ?? "/artist/:uniqueName",
    children: [],
    meta: _91uniqueName_93vye2DmV2HRMeta || {},
    alias: _91uniqueName_93vye2DmV2HRMeta?.alias || [],
    redirect: _91uniqueName_93vye2DmV2HRMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/artist/[uniqueName].vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93ae9ROS6rOgMeta?.name ?? "call-orderId",
    path: _91orderId_93ae9ROS6rOgMeta?.path ?? "/call/:orderId",
    children: [],
    meta: _91orderId_93ae9ROS6rOgMeta || {},
    alias: _91orderId_93ae9ROS6rOgMeta?.alias || [],
    redirect: _91orderId_93ae9ROS6rOgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/call/[orderId].vue").then(m => m.default || m)
  },
  {
    name: calls5zalHHdL4EMeta?.name ?? "calls",
    path: calls5zalHHdL4EMeta?.path ?? "/calls",
    children: [],
    meta: calls5zalHHdL4EMeta || {},
    alias: calls5zalHHdL4EMeta?.alias || [],
    redirect: calls5zalHHdL4EMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/calls.vue").then(m => m.default || m)
  },
  {
    name: chat1HrNrTjf3wMeta?.name ?? "chat",
    path: chat1HrNrTjf3wMeta?.path ?? "/chat",
    children: [],
    meta: chat1HrNrTjf3wMeta || {},
    alias: chat1HrNrTjf3wMeta?.alias || [],
    redirect: chat1HrNrTjf3wMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: dashboardHdtnClvoGAMeta?.name ?? "dashboard",
    path: dashboardHdtnClvoGAMeta?.path ?? "/dashboard",
    children: [],
    meta: dashboardHdtnClvoGAMeta || {},
    alias: dashboardHdtnClvoGAMeta?.alias || [],
    redirect: dashboardHdtnClvoGAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: demo_45submissions_45oldgo0xd4ZsYoMeta?.name ?? "demo-submissions-old",
    path: demo_45submissions_45oldgo0xd4ZsYoMeta?.path ?? "/demo-submissions-old",
    children: [],
    meta: demo_45submissions_45oldgo0xd4ZsYoMeta || {},
    alias: demo_45submissions_45oldgo0xd4ZsYoMeta?.alias || [],
    redirect: demo_45submissions_45oldgo0xd4ZsYoMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/demo-submissions-old.vue").then(m => m.default || m)
  },
  {
    name: discoverDujjR35yg8Meta?.name ?? "discover",
    path: discoverDujjR35yg8Meta?.path ?? "/discover",
    children: [],
    meta: discoverDujjR35yg8Meta || {},
    alias: discoverDujjR35yg8Meta?.alias || [],
    redirect: discoverDujjR35yg8Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/discover.vue").then(m => m.default || m)
  },
  {
    name: feedback1zgaUMjbuXMeta?.name ?? "feedback",
    path: feedback1zgaUMjbuXMeta?.path ?? "/feedback",
    children: [],
    meta: feedback1zgaUMjbuXMeta || {},
    alias: feedback1zgaUMjbuXMeta?.alias || [],
    redirect: feedback1zgaUMjbuXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/feedback.vue").then(m => m.default || m)
  },
  {
    name: impersonaterw9JypSkoGMeta?.name ?? "impersonate",
    path: impersonaterw9JypSkoGMeta?.path ?? "/impersonate",
    children: [],
    meta: impersonaterw9JypSkoGMeta || {},
    alias: impersonaterw9JypSkoGMeta?.alias || [],
    redirect: impersonaterw9JypSkoGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/impersonate.vue").then(m => m.default || m)
  },
  {
    name: index_45oldVNbYTfRXPlMeta?.name ?? "index-old",
    path: index_45oldVNbYTfRXPlMeta?.path ?? "/index-old",
    children: [],
    meta: index_45oldVNbYTfRXPlMeta || {},
    alias: index_45oldVNbYTfRXPlMeta?.alias || [],
    redirect: index_45oldVNbYTfRXPlMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index-old.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    children: [],
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93guCiENn06qMeta?.name ?? "industry-pros-slugs",
    path: _91_46_46_46slugs_93guCiENn06qMeta?.path ?? "/industry-pros/:slugs(.*)*",
    children: [],
    meta: _91_46_46_46slugs_93guCiENn06qMeta || {},
    alias: _91_46_46_46slugs_93guCiENn06qMeta?.alias || [],
    redirect: _91_46_46_46slugs_93guCiENn06qMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/industry-pros/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: indexh0nIylRQ4kMeta?.name ?? "industry-pros",
    path: indexh0nIylRQ4kMeta?.path ?? "/industry-pros",
    children: [],
    meta: indexh0nIylRQ4kMeta || {},
    alias: indexh0nIylRQ4kMeta?.alias || [],
    redirect: indexh0nIylRQ4kMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/industry-pros/index.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    children: [],
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: musicidQLHAkdMCMeta?.name ?? "music",
    path: musicidQLHAkdMCMeta?.path ?? "/music",
    children: [],
    meta: musicidQLHAkdMCMeta || {},
    alias: musicidQLHAkdMCMeta?.alias || [],
    redirect: musicidQLHAkdMCMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/music.vue").then(m => m.default || m)
  },
  {
    name: my_45_91type_93_45profileyebcZFoPCMMeta?.name ?? "my-type-profile",
    path: my_45_91type_93_45profileyebcZFoPCMMeta?.path ?? "/my-:type-profile",
    children: [],
    meta: my_45_91type_93_45profileyebcZFoPCMMeta || {},
    alias: my_45_91type_93_45profileyebcZFoPCMMeta?.alias || [],
    redirect: my_45_91type_93_45profileyebcZFoPCMMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/my-[type]-profile.vue").then(m => m.default || m)
  },
  {
    name: _91opportunitySlug_93YSbCCvcanJMeta?.name ?? "my-opportunities-old-opportunitySlug",
    path: _91opportunitySlug_93YSbCCvcanJMeta?.path ?? "/my-opportunities-old/:opportunitySlug",
    children: [
  {
    name: _91opportunitySubmissionId_93OkeYZvvWX6Meta?.name ?? "my-opportunities-old-opportunitySlug-opportunitySubmissionId",
    path: _91opportunitySubmissionId_93OkeYZvvWX6Meta?.path ?? ":opportunitySubmissionId",
    children: [],
    meta: _91opportunitySubmissionId_93OkeYZvvWX6Meta || {},
    alias: _91opportunitySubmissionId_93OkeYZvvWX6Meta?.alias || [],
    redirect: _91opportunitySubmissionId_93OkeYZvvWX6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/my-opportunities-old/[opportunitySlug]/[opportunitySubmissionId].vue").then(m => m.default || m)
  }
],
    meta: _91opportunitySlug_93YSbCCvcanJMeta || {},
    alias: _91opportunitySlug_93YSbCCvcanJMeta?.alias || [],
    redirect: _91opportunitySlug_93YSbCCvcanJMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/my-opportunities-old/[opportunitySlug].vue").then(m => m.default || m)
  },
  {
    name: index30vJRR94BfMeta?.name ?? "my-opportunities-old",
    path: index30vJRR94BfMeta?.path ?? "/my-opportunities-old",
    children: [],
    meta: index30vJRR94BfMeta || {},
    alias: index30vJRR94BfMeta?.alias || [],
    redirect: index30vJRR94BfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/my-opportunities-old/index.vue").then(m => m.default || m)
  },
  {
    name: _91opportunitySlug_934GQ4u23KBhMeta?.name ?? "my-opportunities-opportunitySlug",
    path: _91opportunitySlug_934GQ4u23KBhMeta?.path ?? "/my-opportunities/:opportunitySlug",
    children: [],
    meta: _91opportunitySlug_934GQ4u23KBhMeta || {},
    alias: _91opportunitySlug_934GQ4u23KBhMeta?.alias || [],
    redirect: _91opportunitySlug_934GQ4u23KBhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/my-opportunities/[opportunitySlug].vue").then(m => m.default || m)
  },
  {
    name: indexVTA3xhc60HMeta?.name ?? "my-opportunities",
    path: indexVTA3xhc60HMeta?.path ?? "/my-opportunities",
    children: [],
    meta: indexVTA3xhc60HMeta || {},
    alias: indexVTA3xhc60HMeta?.alias || [],
    redirect: indexVTA3xhc60HMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/my-opportunities/index.vue").then(m => m.default || m)
  },
  {
    name: _91opportunityId_93QSGozP7tnNMeta?.name ?? "opportunity-opportunityId",
    path: _91opportunityId_93QSGozP7tnNMeta?.path ?? "/opportunity/:opportunityId",
    children: [
  {
    name: profiletSnesj0NHTMeta?.name ?? "opportunity-opportunityId-profile",
    path: profiletSnesj0NHTMeta?.path ?? "profile",
    children: [],
    meta: profiletSnesj0NHTMeta || {},
    alias: profiletSnesj0NHTMeta?.alias || [],
    redirect: profiletSnesj0NHTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/opportunity/[opportunityId]/profile.vue").then(m => m.default || m)
  },
  {
    name: questionDu3ra51uz0Meta?.name ?? "opportunity-opportunityId-question",
    path: questionDu3ra51uz0Meta?.path ?? "question",
    children: [],
    meta: questionDu3ra51uz0Meta || {},
    alias: questionDu3ra51uz0Meta?.alias || [],
    redirect: questionDu3ra51uz0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/opportunity/[opportunityId]/question.vue").then(m => m.default || m)
  },
  {
    name: select_45trackLMmVV6usUaMeta?.name ?? "opportunity-opportunityId-select-track",
    path: select_45trackLMmVV6usUaMeta?.path ?? "select-track",
    children: [],
    meta: select_45trackLMmVV6usUaMeta || {},
    alias: select_45trackLMmVV6usUaMeta?.alias || [],
    redirect: select_45trackLMmVV6usUaMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/opportunity/[opportunityId]/select-track.vue").then(m => m.default || m)
  }
],
    meta: _91opportunityId_93QSGozP7tnNMeta || {},
    alias: _91opportunityId_93QSGozP7tnNMeta?.alias || [],
    redirect: _91opportunityId_93QSGozP7tnNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/opportunity/[opportunityId].vue").then(m => m.default || m)
  },
  {
    name: success42RBmg2XXuMeta?.name ?? "opportunity-success",
    path: success42RBmg2XXuMeta?.path ?? "/opportunity/success",
    children: [],
    meta: success42RBmg2XXuMeta || {},
    alias: success42RBmg2XXuMeta?.alias || [],
    redirect: success42RBmg2XXuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/opportunity/success.vue").then(m => m.default || m)
  },
  {
    name: post_45onboardingQm5R02hDeHMeta?.name ?? "post-onboarding",
    path: post_45onboardingQm5R02hDeHMeta?.path ?? "/post-onboarding",
    children: [],
    meta: post_45onboardingQm5R02hDeHMeta || {},
    alias: post_45onboardingQm5R02hDeHMeta?.alias || [],
    redirect: post_45onboardingQm5R02hDeHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/post-onboarding.vue").then(m => m.default || m)
  },
  {
    name: _91uniqueId_93zzqNQI3AS5Meta?.name ?? "pro-onboarding-uniqueId",
    path: _91uniqueId_93zzqNQI3AS5Meta?.path ?? "/pro-onboarding/:uniqueId",
    children: [],
    meta: _91uniqueId_93zzqNQI3AS5Meta || {},
    alias: _91uniqueId_93zzqNQI3AS5Meta?.alias || [],
    redirect: _91uniqueId_93zzqNQI3AS5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pro-onboarding/[uniqueId].vue").then(m => m.default || m)
  },
  {
    name: errorM8dYKdYKWpMeta?.name ?? "pro-onboarding-error",
    path: errorM8dYKdYKWpMeta?.path ?? "/pro-onboarding/error",
    children: [],
    meta: errorM8dYKdYKWpMeta || {},
    alias: errorM8dYKdYKWpMeta?.alias || [],
    redirect: errorM8dYKdYKWpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pro-onboarding/error.vue").then(m => m.default || m)
  },
  {
    name: _91uniqueName_93uqzvQIfBmuMeta?.name ?? "purchase-uniqueName",
    path: _91uniqueName_93uqzvQIfBmuMeta?.path ?? "/purchase/:uniqueName",
    children: [],
    meta: _91uniqueName_93uqzvQIfBmuMeta || {},
    alias: _91uniqueName_93uqzvQIfBmuMeta?.alias || [],
    redirect: _91uniqueName_93uqzvQIfBmuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/purchase/[uniqueName].vue").then(m => m.default || m)
  },
  {
    name: checkout8RRcsq18vEMeta?.name ?? "purchase-checkout",
    path: checkout8RRcsq18vEMeta?.path ?? "/purchase/checkout",
    children: [
  {
    name: about_45yourselfGQCBPoR6kpMeta?.name ?? "purchase-checkout-about-yourself",
    path: about_45yourselfGQCBPoR6kpMeta?.path ?? "about-yourself",
    children: [],
    meta: about_45yourselfGQCBPoR6kpMeta || {},
    alias: about_45yourselfGQCBPoR6kpMeta?.alias || [],
    redirect: about_45yourselfGQCBPoR6kpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/purchase/checkout/about-yourself.vue").then(m => m.default || m)
  },
  {
    name: extra_45contentUd5gibn0AMMeta?.name ?? "purchase-checkout-extra-content",
    path: extra_45contentUd5gibn0AMMeta?.path ?? "extra-content",
    children: [],
    meta: extra_45contentUd5gibn0AMMeta || {},
    alias: extra_45contentUd5gibn0AMMeta?.alias || [],
    redirect: extra_45contentUd5gibn0AMMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/purchase/checkout/extra-content.vue").then(m => m.default || m)
  },
  {
    name: feedback_45topicTx2HslfrpMMeta?.name ?? "purchase-checkout-feedback-topic",
    path: feedback_45topicTx2HslfrpMMeta?.path ?? "feedback-topic",
    children: [],
    meta: feedback_45topicTx2HslfrpMMeta || {},
    alias: feedback_45topicTx2HslfrpMMeta?.alias || [],
    redirect: feedback_45topicTx2HslfrpMMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/purchase/checkout/feedback-topic.vue").then(m => m.default || m)
  },
  {
    name: pay2GqWfjYG0sMeta?.name ?? "purchase-checkout-pay",
    path: pay2GqWfjYG0sMeta?.path ?? "pay",
    children: [],
    meta: pay2GqWfjYG0sMeta || {},
    alias: pay2GqWfjYG0sMeta?.alias || [],
    redirect: pay2GqWfjYG0sMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/purchase/checkout/pay.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberPCVO3aRrKsMeta?.name ?? "purchase-checkout-phone-number",
    path: phone_45numberPCVO3aRrKsMeta?.path ?? "phone-number",
    children: [],
    meta: phone_45numberPCVO3aRrKsMeta || {},
    alias: phone_45numberPCVO3aRrKsMeta?.alias || [],
    redirect: phone_45numberPCVO3aRrKsMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/purchase/checkout/phone-number.vue").then(m => m.default || m)
  },
  {
    name: pick_45timeP0wTkVpbpuMeta?.name ?? "purchase-checkout-pick-time",
    path: pick_45timeP0wTkVpbpuMeta?.path ?? "pick-time",
    children: [],
    meta: pick_45timeP0wTkVpbpuMeta || {},
    alias: pick_45timeP0wTkVpbpuMeta?.alias || [],
    redirect: pick_45timeP0wTkVpbpuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/purchase/checkout/pick-time.vue").then(m => m.default || m)
  },
  {
    name: reviewqcYDVqkXt1Meta?.name ?? "purchase-checkout-review",
    path: reviewqcYDVqkXt1Meta?.path ?? "review",
    children: [],
    meta: reviewqcYDVqkXt1Meta || {},
    alias: reviewqcYDVqkXt1Meta?.alias || [],
    redirect: reviewqcYDVqkXt1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/purchase/checkout/review.vue").then(m => m.default || m)
  },
  {
    name: select_45stage4BMtNiXiNYMeta?.name ?? "purchase-checkout-select-stage",
    path: select_45stage4BMtNiXiNYMeta?.path ?? "select-stage",
    children: [],
    meta: select_45stage4BMtNiXiNYMeta || {},
    alias: select_45stage4BMtNiXiNYMeta?.alias || [],
    redirect: select_45stage4BMtNiXiNYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/purchase/checkout/select-stage.vue").then(m => m.default || m)
  },
  {
    name: select_45trackNHMyWeWsY3Meta?.name ?? "purchase-checkout-select-track",
    path: select_45trackNHMyWeWsY3Meta?.path ?? "select-track",
    children: [],
    meta: select_45trackNHMyWeWsY3Meta || {},
    alias: select_45trackNHMyWeWsY3Meta?.alias || [],
    redirect: select_45trackNHMyWeWsY3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/purchase/checkout/select-track.vue").then(m => m.default || m)
  }
],
    meta: checkout8RRcsq18vEMeta || {},
    alias: checkout8RRcsq18vEMeta?.alias || [],
    redirect: checkout8RRcsq18vEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/purchase/checkout.vue").then(m => m.default || m)
  },
  {
    name: success2jT8z7kuIeMeta?.name ?? "purchase-success",
    path: success2jT8z7kuIeMeta?.path ?? "/purchase/success",
    children: [],
    meta: success2jT8z7kuIeMeta || {},
    alias: success2jT8z7kuIeMeta?.alias || [],
    redirect: success2jT8z7kuIeMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/purchase/success.vue").then(m => m.default || m)
  },
  {
    name: _91requestId_93svNXBI9S4OMeta?.name ?? "rating-userId-requestId",
    path: _91requestId_93svNXBI9S4OMeta?.path ?? "/rating/:userId/:requestId",
    children: [],
    meta: _91requestId_93svNXBI9S4OMeta || {},
    alias: _91requestId_93svNXBI9S4OMeta?.alias || [],
    redirect: _91requestId_93svNXBI9S4OMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/rating/[userId]/[requestId].vue").then(m => m.default || m)
  },
  {
    name: signupY93ILdpGpbMeta?.name ?? "signup",
    path: signupY93ILdpGpbMeta?.path ?? "/signup",
    children: [],
    meta: signupY93ILdpGpbMeta || {},
    alias: signupY93ILdpGpbMeta?.alias || [],
    redirect: signupY93ILdpGpbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: indextteqWBX13wMeta?.name ?? "submit",
    path: indextteqWBX13wMeta?.path ?? "/submit",
    children: [],
    meta: indextteqWBX13wMeta || {},
    alias: indextteqWBX13wMeta?.alias || [],
    redirect: indextteqWBX13wMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/submit/index.vue").then(m => m.default || m)
  },
  {
    name: profilekQuiABvXUVMeta?.name ?? "submit-profile",
    path: profilekQuiABvXUVMeta?.path ?? "/submit/profile",
    children: [],
    meta: profilekQuiABvXUVMeta || {},
    alias: profilekQuiABvXUVMeta?.alias || [],
    redirect: profilekQuiABvXUVMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/submit/profile.vue").then(m => m.default || m)
  },
  {
    name: successLuGi3A7cWNMeta?.name ?? "submit-success",
    path: successLuGi3A7cWNMeta?.path ?? "/submit/success",
    children: [],
    meta: successLuGi3A7cWNMeta || {},
    alias: successLuGi3A7cWNMeta?.alias || [],
    redirect: successLuGi3A7cWNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/submit/success.vue").then(m => m.default || m)
  },
  {
    name: trackxkk8w3363SMeta?.name ?? "submit-track",
    path: trackxkk8w3363SMeta?.path ?? "/submit/track",
    children: [],
    meta: trackxkk8w3363SMeta || {},
    alias: trackxkk8w3363SMeta?.alias || [],
    redirect: trackxkk8w3363SMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/submit/track.vue").then(m => m.default || m)
  },
  {
    name: wishlistakmJn3oDDeMeta?.name ?? "wishlist",
    path: wishlistakmJn3oDDeMeta?.path ?? "/wishlist",
    children: [],
    meta: wishlistakmJn3oDDeMeta || {},
    alias: wishlistakmJn3oDDeMeta?.alias || [],
    redirect: wishlistakmJn3oDDeMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: xposure_45playlists5jDMQR6dDKMeta?.name ?? "xposure-playlists",
    path: xposure_45playlists5jDMQR6dDKMeta?.path ?? "/xposure-playlists",
    children: [],
    meta: xposure_45playlists5jDMQR6dDKMeta || {},
    alias: xposure_45playlists5jDMQR6dDKMeta?.alias || [],
    redirect: xposure_45playlists5jDMQR6dDKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/xposure-playlists.vue").then(m => m.default || m)
  }
]